import classNames from 'clsx'

const LogoImage: React.FCC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={105}
      id="Layer_1"
      viewBox="0 0 319.78 139.66"
      className={classNames(`w-[95px] sm:w-[105px]`, className)}
    >
      <defs>
        <style>{'.cls-1{fill:#0284c5;stroke-width:0}'}</style>
      </defs>
      <path
        d="M84.52 26.08H0v20.65h28.72v92.93h26.14V46.73h29.66V26.08z"
        className="cls-1"
      />
      <path
        d="M105.52 56.41c-14.84 0-27.27 4.68-34.04 8.07l6.78 16.78c4.03-2.58 14.2-7.1 24.36-7.1s16.3 4.36 16.3 12.75v1.77l-20.01.81c-14.68.65-30.98 6.62-30.98 24.36s14.84 25.33 28.88 25.33c12.91 0 19.36-6.78 22.75-10.16l.48 8.39h24.69V92.56c0-25.81-14.36-36.14-39.21-36.14Zm13.55 59.05c-1.61 2.74-6.29 7.26-13.23 7.26s-12.1-3.71-12.1-9.84 4.68-9.36 12.58-9.68l12.75-.81v13.07ZM157.31 26.08h25.81V137.4h-25.81zM197 61.01h25.81v76.4H197zM275.73 56.41c-25.17 0-44.21 15.81-44.21 41.46s19.04 41.3 44.21 41.3 44.05-15.65 44.05-41.3-18.88-41.46-44.05-41.46Zm0 61.64c-11.45 0-18.07-8.87-18.07-20.65s6.62-20.65 18.07-20.65 18.07 8.87 18.07 20.65-6.78 20.65-18.07 20.65ZM249.2 26.08C249.2 11.68 237.52 0 223.12 0s-26.08 11.68-26.08 26.08v26.08h26.08c14.4 0 26.08-11.68 26.08-26.08Zm-52.16 0h26.08v26.08c-14.4 0-26.08-11.68-26.08-26.08Z"
        className="cls-1"
      />
    </svg>
  )
}

export default LogoImage
